import Layout from '@components/layout/Layout';
import ContactForm from '@components/ui/contact-form/ContactForm';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import TitleBar from '@components/ui/title-bar/TitleBar';
import { PhoneIcon } from '@heroicons/react/outline';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import LanguageProvider from '../lib/language.provider';

const KontaktPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>Hello World</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 md:flex">
								<div className="pt-10 pr-16 w-full text-white md:max-w-sm lg:max-w-xl">
									<h1 className="font-serif text-4xl font-bold">
										<Trans>Kontakt</Trans>
									</h1>
								</div>
								<div className="relative w-full">
									<div className="z-10 md:max-w-lg lg:ml-14 md:absolute">
										<div className="w-full bg-white">
											<ContactForm />
										</div>
									</div>
								</div>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../assets/images/kontakt-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div className="flex-1 bg-gray-light">
							<Container>
								<div className="py-8 pr-16 w-full md:max-w-sm lg:max-w-xl md:pt-12 md:pb-36">
									<h2 className="mb-4 text-xl font-bold">
										Iwanejko-Taczkowska
										<br /> Legal & Advisory
									</h2>
									<div className="grid grid-cols-2 gap-4 text-sm md:grid-cols-1 lg:grid-cols-2 w-500">
										<div>
											<p>ul. Stefana Batorego 28-32 lok. 107</p>
											<p>81-366 Gdynia</p>
										</div>
										<div>
											<p className="flex">
												<span className="w-16 font-bold">NIP:</span>671 165 69
												33
											</p>
											<p className="flex">
												<span className="w-16 font-bold">REGON:</span>193 069
												720
											</p>
										</div>
									</div>
									<div className="flex items-center py-5 text-sm">
										<PhoneIcon className="mr-3 w-6 text-blue" />
										<span>+48 691 767 003</span>
									</div>
								</div>
							</Container>
						</div>
						<Footer background={false} />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default KontaktPage;
