import { t } from '@lingui/macro';
import { FC, useState } from 'react';
import React from 'react';
import { useForm } from 'react-hook-form';

const ContactForm: FC = () => {
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			name: '',
			email: '',
			message: '',
			compliance: false
		}
	});

	const [isFormSent, setIsFormSent] = useState(false);

	const onSubmit = (data) => {
		return fetch('/mailer/mail-script.php', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(() => setIsFormSent(true))
			.then(() => reset());
	};

	return (
		<>
			<form
				noValidate
				className="p-10 text-gray-dark"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="grid grid-cols-1 gap-3">
					<div>
						<label className="block mb-2 text-sm flex justify-between">
							<span className="font-bold">Imię i nazwisko</span>
							<span className="text-xs text-red">{errors?.name?.message}</span>
						</label>
						<input
							{...register('name', {
								minLength: 3,
								required: t`Pole wymagane`
							})}
							className="block px-2 w-full h-10 border border-gray-medium focus:outline-none focus:bg-gray-light"
							type="text"
						/>
					</div>

					<div>
						<label className="block mb-2 text-sm flex justify-between">
							<span className="font-bold">Adres email</span>
							<span className="text-xs text-red">{errors?.email?.message}</span>
						</label>
						<input
							{...register('email', {
								minLength: 3,
								required: t`Pole wymagane`,
								pattern: {
									value: /^[^@\s]+@[^@\s]+\.[^@.\s]+$/,
									message: 'Nieprawidłowy adres email'
								}
							})}
							className="block px-2 w-full h-10 border border-gray-medium focus:outline-none focus:bg-gray-light"
							type="email"
						/>
					</div>

					<div>
						<label className="block mb-2 text-sm flex justify-between">
							<span className="font-bold">Wiadomość</span>
							<span className="text-xs text-red">
								{errors?.message?.message}
							</span>
						</label>
						<textarea
							{...register('message', {
								minLength: { value: 10, message: t`Zbyt krótkka wiadomość` },
								required: t`Pole wymagane`
							})}
							className="border border-gray-medium w-full h-20 resize-none focus:outline-none px-2 py-1.5 focus:bg-gray-light"
						/>
					</div>

					<div className="mb-7">
						<div className="flex space-x-5">
							<div className="flex-grow-0">
								<input
									{...register('compliance', {
										required: t`Zgoda wymagana`
									})}
									className="w-6 h-6"
									type="checkbox"
								/>
							</div>
							<label className="text-sm">
								<span className="block">
									Wyrażam zgodę na przetwarzanie podanego adresu e-mail w celu
									udzielenia odpowiedzi na przesłaną wiadomość.
								</span>
								<span className="text-xs text-red blck">
									{errors?.compliance?.message}
								</span>
							</label>
						</div>
					</div>
				</div>

				<div className="flex justify-between items-center">
					<button className="py-4 px-10 text-sm font-medium text-white uppercase transition duration-300 focus:outline-none bg-blue hover:bg-blue-shade">
						Wyślij
					</button>
					{isFormSent && (
						<div className="font-bold text-sm text-blue">Wiadomość wysłana</div>
					)}
				</div>
			</form>
		</>
	);
};

export default ContactForm;
